import React, { useState, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withTheme } from 'common/styling/theme';
import queryString from 'query-string';

import { PanelTabs } from 'common/components';
import { staticStyles } from './style';
import LeaderboardBudgetSelector from './_components/LeaderboardBudgetSelector';

const LeaderboardSelector = ({ challenges, location, history }) => {
  const firstElemRef = useRef(null);
  const activeTabSlugQuery = useMemo(
    () => (location.search ? queryString.parse(location.search).tab : undefined),
    [location.search]
  );

  const [selectedChallengeId, setSelectedChallengeId] = useState(queryString.parse(location.search).challengeId);
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  useEffect(() => {
    const { challengeId, ...newQuery } = { ...query };
    if (selectedChallengeId !== 'all') {
      newQuery.challengeId = selectedChallengeId;
    }

    // Update the URL only if there's a change in the query
    if (JSON.stringify(newQuery) !== JSON.stringify(query)) {
      history.replace({
        ...location,
        search: queryString.stringify(newQuery),
      });
    }
  }, [selectedChallengeId, activeTabSlugQuery]);

  useEffect(() => {
    if (typeof activeTabSlugQuery === 'undefined') {
      firstElemRef.current.click();
    } else {
      setSelectedGroupId(activeTabSlugQuery === 'All' ? 'All' : +activeTabSlugQuery);
      const selectedGroup = challenges.find(({ id }) => id === +activeTabSlugQuery);

      if (typeof selectedGroup?.challenges?.[0]?.id === 'number') {
        setSelectedChallengeId(selectedGroup?.challenges?.[0]?.id);
      }

      // If the active tab is 'All', update the URL
      if (activeTabSlugQuery === 'All') {
        const { challengeId, ...newQuery } = { ...query };
        history.replace({
          ...location,
          search: queryString.stringify(newQuery),
        });
      }
    }
  }, [activeTabSlugQuery, challenges]);

  const challengesBudgetSelectorValues = useMemo(() => {
    const selectedGroup = challenges?.find(({ id }) => id === selectedGroupId);
    if (!selectedGroup || !selectedGroup.challenges.length) {
      return [];
    }
    return selectedGroup.challenges.map(challenge => ({
      initialAccountBalance: challenge.initialAccountBalance,
      id: challenge.id,
    }));
  }, [selectedChallengeId, selectedGroupId, challenges]);

  const onChallengeSelect = id => {
    setSelectedChallengeId(id);
  };

  return (
    <div className="LeaderboardSelector">
      <div className="LeaderboardSelector__head">
        <PanelTabs
          firstElemRef={firstElemRef}
          activeTabSlug={selectedGroupId}
          location={location}
          history={history}
          noTranslate>
          {[{ title: 'All', id: 'All' }, ...challenges].map((group, ind) => (
            <div
              key={`${group.title}${ind}`}
              className="LeaderboardSelector__Content"
              textId={group.title}
              slug={group.id}
              id={group.id}>
              {challengesBudgetSelectorValues && (
                <LeaderboardBudgetSelector
                  data={challengesBudgetSelectorValues}
                  onChange={onChallengeSelect}
                  selectedChallengeId={selectedChallengeId}
                />
              )}
            </div>
          ))}
        </PanelTabs>
      </div>

      <style jsx>{staticStyles}</style>
    </div>
  );
};

export default compose(withTheme(), withRouter, connect(null, {}))(LeaderboardSelector);
export { LeaderboardSelector };
