import { put, call, fork, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';

import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';
import { getProfileSaga } from 'interface/user/_redux/sagas';
import {
  getPartnerAccountsSaga,
  getPartnerAccountSaga,
  getPartnerAccountCommissionsSaga,
} from 'client/_redux/commonSagas/sagas';

import * as api from '../api';
import * as ActionTypes from './actions';

function* getUserTagsClientSaga(id) {
  try {
    const { data } = yield call(api.getUserTagsClient, id);
    yield put(ActionTypes.getUserTagsClient.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getUserTagsClient.failure(e));
  }
}

function* getUserTagsClientWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_USER_TAGS_CLIENT[REQUEST]);
    yield call(getUserTagsClientSaga, id);
  }
}

export function* requestPartnershipSaga() {
  try {
    const { status, data } = yield call(api.requestPartnership);
    if (status < 300) {
      yield put(ActionTypes.requestPartnership.success(data));
      yield call(getProfileSaga);
    } else {
      yield put(ActionTypes.requestPartnership.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.requestPartnership.failure());
  }
}

export function* requestPartnershipWatcherSaga() {
  while (true) {
    yield take(ActionTypes.REQUEST_PARTNERSHIP[REQUEST]);
    yield call(requestPartnershipSaga);
  }
}

export function* getPartnershipInfoSaga() {
  try {
    const { status, data } = yield call(api.getPartnershipInfo);
    if (status < 300) {
      yield put(ActionTypes.getPartnershipInfo.success(data));
    } else {
      yield put(ActionTypes.getPartnershipInfo.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getPartnershipInfo.failure());
  }
}

export function* getPartnershipInfoWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_PARTNERSHIP_INFO[REQUEST]);
    yield call(getPartnershipInfoSaga);
  }
}

export function* getPartnershipClientsBalanceSaga() {
  try {
    const { status, data } = yield call(api.getPartnershipClientsBalance);
    if (status < 300) {
      yield put(ActionTypes.getPartnershipClientsBalance.success(data));
    } else {
      yield put(ActionTypes.getPartnershipClientsBalance.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getPartnershipClientsBalance.failure());
  }
}

export function* getPartnershipClientsBalanceWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_PARTNERSHIP_CLIENTS_BALANCE[REQUEST]);
    yield call(getPartnershipClientsBalanceSaga);
  }
}

export function* getPartnershipClientsSaga({ search }) {
  try {
    const { status, data } = yield call(api.getPartnershipClients, search);
    if (status < 300) {
      yield put(ActionTypes.getPartnershipClients.success(data));
    } else {
      yield put(ActionTypes.getPartnershipClients.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getPartnershipClients.failure());
  }
}

export function* getPartnershipClientsWatcherSaga() {
  while (true) {
    const { data } = yield take(ActionTypes.GET_PARTNERSHIP_CLIENTS[REQUEST]);
    yield call(getPartnershipClientsSaga, data);
  }
}

export function* getPartnershipClientAccountsSaga(clientId, full) {
  try {
    const { data } = yield call(api.getPartnershipClientAccounts, clientId);
    yield put(ActionTypes.getPartnershipClientAccounts.success(data));
    if (full) {
      for (let i = 0; i < data.length; i += 1) {
        yield put(ActionTypes.getPartnershipClientAccount.request(clientId, data[i].id));
      }
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getPartnershipClientAccounts.failure(e));
  }
}

export function* getPartnershipClientAccountsWatcherSaga() {
  while (true) {
    const { clientId, full } = yield take(ActionTypes.GET_PARTNERSHIP_CLIENT_ACCOUNTS[REQUEST]);
    yield call(getPartnershipClientAccountsSaga, clientId, full);
  }
}

export function* getPartnershipClientAccountSaga({ clientId, accountId }) {
  try {
    const { data } = yield call(api.getPartnershipClientAccount, clientId, accountId);
    yield put(ActionTypes.getPartnershipClientAccount.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getPartnershipClientAccount.failure(accountId, e));
  }
}

export function* getPartnershipClientAccountWatcherSaga() {
  yield takeEvery(ActionTypes.GET_PARTNERSHIP_CLIENT_ACCOUNT[REQUEST], getPartnershipClientAccountSaga);
}

export function* getPartnershipClientTradesSaga({ clientId, accountId, status = 'open', startDate, endDate }) {
  try {
    const response = yield call(api.getPartnershipClientTrades, clientId, accountId, status, startDate, endDate);
    if (response.status < 300) {
      yield put(ActionTypes.getPartnershipClientTrades.success(accountId, status, response.data));
    } else {
      yield put(ActionTypes.getPartnershipClientTrades.failure(response.data));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getPartnershipClientTrades.failure(accountId, e));
  }
}

export function* getPartnershipClientTradesWatcherSaga() {
  yield takeLatest(ActionTypes.GET_PARTNERSHIP_CLIENT_TRADES[REQUEST], getPartnershipClientTradesSaga);
}

export function* getFullPartnershipClientAccountSaga({ clientId, accountId }) {
  const numericId = Number(accountId);
  if (!Number.isInteger(numericId)) {
    throw new Error('accountId should be Integer compatible');
  }
  try {
    yield call(getPartnershipClientAccountsSaga, clientId, false);
    yield put(ActionTypes.getPartnershipClientAccount.request(clientId, numericId));
    yield put(ActionTypes.getFullPartnershipClientAccount.success(clientId, numericId));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getFullPartnershipClientAccount.failure(e));
  }
}

export function* getFullPartnershipClientAccountWatcherSaga() {
  yield takeEvery(ActionTypes.GET_FULL_PARTNERSHIP_CLIENT_ACCOUNT[REQUEST], getFullPartnershipClientAccountSaga);
}

export function* getCurrentIBProgramsSaga() {
  try {
    const { data } = yield call(api.getCurrentIBPrograms);
    yield put(ActionTypes.getCurrentIBPrograms.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getCurrentIBPrograms.failure());
  }
}
export function* getCurrentIBProgramsWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_CURRENT_IB_PROGRAMS[REQUEST]);
    yield call(getCurrentIBProgramsSaga);
  }
}

export function* getSimpleIBSystemSettingsSaga() {
  try {
    const { data } = yield call(api.getSimpleIBSystemSettings);
    yield put(ActionTypes.getSimpleIBSystemSettings.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getSimpleIBSystemSettings.failure());
  }
}
export function* getSimpleIBSystemSettingsWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_SIMPLE_IB_SYSTEM_SETTINGS[REQUEST]);
    yield call(getSimpleIBSystemSettingsSaga);
  }
}

export function* sendSimpleIBSystemSettingsSaga(settings) {
  try {
    const { status, data } = yield call(api.sendSimpleIBSystemSettings, settings);
    if (status < 300) {
      yield put(ActionTypes.sendSimpleIBSystemSettings.success());
    } else {
      const error = data.detail ? { _error: data.detail } : data;
      yield put(ActionTypes.sendSimpleIBSystemSettings.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.sendSimpleIBSystemSettings.failure());
  }
}
export function* sendSimpleIBSystemSettingsWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.sendSimpleIBSystemSettings.REQUEST);
    yield call(sendSimpleIBSystemSettingsSaga, payload);
  }
}

export function* getPartnershipMaterialsSaga({ promoType, query: { tab, ...params } }) {
  try {
    const { data } = yield call(api.getPartnershipMaterials, promoType, params);
    yield put(ActionTypes.getPartnershipMaterials.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getPartnershipMaterials.failure());
  }
}
export function* getPartnershipMaterialsWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.GET_PARTNERSHIP_MATERIALS[REQUEST]);
    yield call(getPartnershipMaterialsSaga, payload);
  }
}

export function* getPartnershipWithdrawalAccountsSaga({ partnerAccountId }) {
  try {
    const { status, data } = yield call(api.getPartnershipWithdrawalAccounts, partnerAccountId);
    if (status < 300) {
      yield put(ActionTypes.getPartnershipWithdrawalAccounts.success(data));
    } else {
      yield put(ActionTypes.getPartnershipWithdrawalAccounts.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getPartnershipWithdrawalAccounts.failure());
  }
}

export function* getPartnershipWithdrawalAccountsWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_PARTNERSHIP_WITHDRAWAL_ACCOUNTS[REQUEST]);
    yield call(getPartnershipWithdrawalAccountsSaga, data);
  }
}

export function* withdrawPartnershipAccountSaga({ partnerAccountId, accountId, toWallet }) {
  try {
    const { status, data } = yield call(api.withdrawPartnershipAccount, partnerAccountId, accountId, toWallet);
    if (status < 300) {
      yield put(ActionTypes.withdrawPartnershipAccount.success());
      if (partnerAccountId) {
        yield call(getPartnerAccountSaga, { accountId: partnerAccountId });
      }
    } else {
      const error = data.detail ? { _error: data.detail } : data;
      yield put(ActionTypes.withdrawPartnershipAccount.failure(new SubmissionError({ accountId: error })));
    }
    yield call(getPartnerAccountsSaga);
    yield call(getPartnerAccountCommissionsSaga, { accountId: partnerAccountId, search: { page: 1, page_size: 10 } });
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.withdrawPartnershipAccount.failure());
  }
}
export function* withdrawPartnershipAccountWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.withdrawPartnershipAccount.REQUEST);
    yield call(withdrawPartnershipAccountSaga, payload);
  }
}

export function* changeRebateSaga({ programId, rebate }) {
  try {
    const { status, data } = yield call(api.changeRebate, programId, rebate);
    if (status < 300) {
      yield put(ActionTypes.changeRebate.success());
      yield call(getCurrentIBProgramsSaga);
    } else {
      const error = data.detail ? { _error: data.detail } : data;
      yield put(ActionTypes.changeRebate.failure(new SubmissionError({ accountId: error })));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.changeRebate.failure());
  }
}
export function* changeRebateWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.changeRebate.REQUEST);
    yield call(changeRebateSaga, payload);
  }
}

export function* getCampaignsSaga() {
  try {
    const { data } = yield call(api.getCampaigns);
    yield put(ActionTypes.getCampaigns.success(data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getCampaigns.failure());
  }
}
export function* getCampaignsWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_CAMPAIGNS[REQUEST]);
    yield call(getCampaignsSaga);
  }
}

export function* getRotatorLinkSaga({ link }) {
  try {
    yield put(ActionTypes.getRotatorLink.success(link));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getRotatorLink.failure());
  }
}
export function* getRotatorLinkWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.GET_ROTATOR_LINK[REQUEST]);
    yield call(getRotatorLinkSaga, payload);
  }
}

export function* getBannerLinkSaga({ link }) {
  try {
    yield put(ActionTypes.getBannerLink.success(link));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getBannerLink.failure());
  }
}
export function* getBannerLinkkWatcherSaga() {
  while (true) {
    const payload = yield take(ActionTypes.GET_BANNER_LINK[REQUEST]);
    yield call(getBannerLinkSaga, payload);
  }
}

export function* createBannersSaga({ isRotator, ...values }) {
  try {
    const { status, data } = yield call(api.createBanners, values);
    if (status < 300) {
      yield put(ActionTypes.createBanners.success(data));
      if (isRotator) {
        yield call(getRotatorLinkSaga, data);
      } else {
        yield call(getBannerLinkSaga, data);
      }
    } else {
      const error = data.detail ? { _error: data.detail } : data;
      yield put(ActionTypes.createBanners.failure(error));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createBanners.failure());
  }
}

export function* createBannersWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createBanners.REQUEST);
    yield call(createBannersSaga, payload);
  }
}

export function* requestEasyStartSaga() {
  try {
    const { status, data } = yield call(api.requestEasyStart);
    if (status < 300) {
      yield put(ActionTypes.requestEasyStart.success());
    } else {
      yield put(ActionTypes.requestEasyStart.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.requestEasyStart.failure());
  }
}

export function* requestEasyStartWatcherSaga() {
  while (true) {
    yield take(ActionTypes.REQUEST_EASY_START[REQUEST]);
    yield call(requestEasyStartSaga);
  }
}

export default [
  fork(getUserTagsClientWatcherSaga),
  fork(requestPartnershipWatcherSaga),
  fork(getPartnershipInfoWatcherSaga),
  fork(getPartnershipClientsBalanceWatcherSaga),
  fork(getPartnershipClientsWatcherSaga),
  fork(getPartnershipClientAccountsWatcherSaga),
  fork(getPartnershipClientAccountWatcherSaga),
  fork(getPartnershipClientTradesWatcherSaga),
  fork(getFullPartnershipClientAccountWatcherSaga),
  fork(getCurrentIBProgramsWatcherSaga),
  fork(getSimpleIBSystemSettingsWatcherSaga),
  fork(sendSimpleIBSystemSettingsWatcherSaga),
  fork(getPartnershipMaterialsWatcherSaga),
  fork(getPartnershipWithdrawalAccountsWatcherSaga),
  fork(withdrawPartnershipAccountWatcherSaga),
  fork(changeRebateWatcherSaga),
  fork(getCampaignsWatcherSaga),
  fork(getRotatorLinkWatcherSaga),
  fork(createBannersWatcherSaga),
  fork(requestEasyStartWatcherSaga),
];
