import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';
import { createFormAction } from 'redux-form-saga';

export const GET_USER_CHALLENGES = createRequestTypes('GET_USER_CHALLENGES');
export const getUserChallenges = {
  request: () => action(GET_USER_CHALLENGES[REQUEST]),
  success: data => action(GET_USER_CHALLENGES[SUCCESS], { data }),
  failure: error => action(GET_USER_CHALLENGES[FAILURE], { error }),
};

export const GET_CHALLENGES = createRequestTypes('GET_CHALLENGES');
export const getChallenges = {
  request: () => action(GET_CHALLENGES[REQUEST]),
  success: data => action(GET_CHALLENGES[SUCCESS], { data }),
  failure: error => action(GET_CHALLENGES[FAILURE], { error }),
};

export const activateChallenge = createFormAction('ACTIVATE_CHALLENGE');

export const changeRefundableFeeRecieveMethod = createFormAction('CHANGE_REFUNDABLE_METHOD');

export const GET_CHALLENGE = createRequestTypes('GET_CHALLENGE');
export const getChallenge = {
  request: id => action(GET_CHALLENGE[REQUEST], { id }),
  success: data => action(GET_CHALLENGE[SUCCESS], { data }),
  failure: error => action(GET_CHALLENGE[FAILURE], { error }),
};

export const RESTART_CHALLENGE = createRequestTypes('RESTART_CHALLENGE');
export const restartChallenge = {
  request: id => action(RESTART_CHALLENGE[REQUEST], { id }),
  success: data => action(RESTART_CHALLENGE[SUCCESS], { data }),
  failure: error => action(RESTART_CHALLENGE[FAILURE], { error }),
};

export const ARCHIVE_CHALLENGE = createRequestTypes('ARCHIVE_CHALLENGE');
export const archiveChallenge = {
  request: id => action(ARCHIVE_CHALLENGE[REQUEST], { id }),
  success: data => action(ARCHIVE_CHALLENGE[SUCCESS], { data }),
  failure: error => action(ARCHIVE_CHALLENGE[FAILURE], { error }),
};

export const GET_CHALLENGES_LEADERBOARD = createRequestTypes('GET_CHALLENGES_LEADERBOARD');
export const getChallegesLeaderboard = {
  request: data => action(GET_CHALLENGES_LEADERBOARD[REQUEST], data),
  success: data => action(GET_CHALLENGES_LEADERBOARD[SUCCESS], { data }),
  failure: error => action(GET_CHALLENGES_LEADERBOARD[FAILURE], { error }),
};
