import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  StatusCell,
  PaginationTable,
  DateCell,
  DefaultButton,
  BaseSelectField,
  BasicModal,
  RowLink,
  DateRangeField,
  PaymentTag,
} from 'common/components';
import { withTheme } from 'common/styling/theme';
import Checkbox from 'backoffice/partnership/_components/Checkbox';

import { createBackOfficePaymentExport, getPaymentMetadataKeys } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const yearsOptions = [...Array(10).keys()].map(i => ({
  value: moment().year() - i,
  label: moment().year() - i,
}));

const PaymentsTable = ({
  data,
  allowManagePaymentsExports,
  exportButtonCaptionSlug,
  isExportPaymentBeingCreated,
  createBackOfficePaymentExport,
  onRowClick,
  getList,
  loading,
  history,
  type,
  location,
  params,
  theme,
  intl,
  hasPaymentOperationManagers,
  getPaymentMetadataKeys,
  metadataKeys,
}) => {
  const slugsMap = {
    deposits: 'deposit',
    withdrawals: 'withdraw',
  };

  const [onlyExecutedChecked, setOnlyExecutedChecked] = useState(false);

  const handeToggleOnlyExecutedField = () => {
    setOnlyExecutedChecked(checked => !checked);
  };

  const pushToExportsFunc = useCallback(() => history.push(`/backoffice/${type}/exports`), [type]);
  const exportsType = useMemo(() => {
    if (type === 'deposits') {
      return 'deposit_export';
    }
    return 'withdraw_export';
  }, []);

  const savedMetada = JSON.parse(localStorage.getItem('savedColumns')) || [];

  const [selectedMetadata, setSelectedMetadata] = useState(savedMetada);

  const metadataOptions = metadataKeys.map(item => ({ label: item, value: item }));

  const handleMetadataChange = values => {
    if (values) {
      setSelectedMetadata([...values]);
      localStorage.setItem('savedColumns', JSON.stringify([...values]));

      return;
    }

    setSelectedMetadata([]);
    localStorage.setItem('savedColumns', JSON.stringify([]));
  };

  const extraColumns = selectedMetadata.map(item => ({
    key: item.value,
    Cell: ({ original: { metadata } }) => <div>{metadata[item.value] || '-'}</div>,
  }));

  const getRowLink = id => {
    let link = '';

    if (type === 'deposits') {
      link = `/backoffice/${type}/${id}/`;
    } else {
      link = `/backoffice/${type}/list/${id}/`;
    }

    return link;
  };

  /* eslint-disable */
  const columns = useMemo(
    () => [
      {
        key: 'boId',
        accessor: 'id',
        Cell: ({ original: { id } }) => (
          <RowLink link={getRowLink(id)}>
            <span className="id">{id}</span>
          </RowLink>
        ),
      },
      {
        key: 'justEmail',
        accessor: 'user__email',
        Cell: ({ original: { user, id } }) => (
          <RowLink link={getRowLink(id)} isPreviewCell>
            <span>{user.email}</span>
          </RowLink>
        ),
        isPreviewCell: true,
      },
      {
        key: 'boAccount',
        accessor: 'account',
        Cell: ({ original: { account, id } }) => (
          <RowLink link={getRowLink(id)}>
            <span className="account">{account?.login ?? intl.formatMessage({ id: 'boWallet' })}</span>
          </RowLink>
        ),
      },
      {
        key: 'boAmount',
        Cell: ({ original: { amount, id } }) => (
          <RowLink link={getRowLink(id)} isPreviewCell>
            <span className="amount">
              {amount.amount} {amount.currency}
            </span>
          </RowLink>
        ),
        isPreviewCell: true,
      },
      {
        key: 'justCommission',
        Cell: ({ original: { commission, id } }) => (
          <RowLink link={getRowLink(id)}>
            <span className="commission">
              {commission?.amount} {commission?.currency}
            </span>
          </RowLink>
        ),
      },
      ...(hasPaymentOperationManagers
        ? [
            {
              key: 'justManager',
              Cell: ({ original: { manager, id } }) => (
                <RowLink link={getRowLink(id)}>
                  <div>{manager ? `${manager.firstName} ${manager.lastName}` : '-'}</div>
                </RowLink>
              ),
            },
          ]
        : []),
      {
        key: 'justConvertationCourse',
        Cell: ({ original: { exchangeString, id } }) => (
          <RowLink link={getRowLink(id)}>
            <span className="convertation-course">{exchangeString}</span>
          </RowLink>
        ),
      },
      {
        key: 'boPaymentSystem',
        Cell: ({ original: { paymentSystemSlug, id } }) => (
          <RowLink link={getRowLink(id)}>
            <span className="payment-system-slug">{paymentSystemSlug}</span>
          </RowLink>
        ),
      },
      {
        key: 'boPaymentMethod',
        Cell: ({ original: { paymentMethodTitle, id } }) => (
          <RowLink link={getRowLink(id)}>
            <span className="payment-method-slug">{paymentMethodTitle}</span>
          </RowLink>
        ),
      },
      {
        key: 'boCreated',
        accessor: 'created',
        Cell: ({ original: { created, id } }) => (
          <RowLink link={getRowLink(id)} isPreviewCell>
            <DateCell value={created} />
          </RowLink>
        ),
        isPreviewCell: true,
      },
      {
        key: 'boStatus',
        accessor: '_status',
        Cell: ({ original: { status, id } }) => (
          <RowLink link={getRowLink(id)} isPreviewCell>
            <div className="status">
              <StatusCell statusCode={status} />
            </div>
          </RowLink>
        ),
        isPreviewCell: true,
      },
      {
        key: 'justPaymentTags',
        Cell: ({ original: { tags, id } }) => (
          <RowLink link={getRowLink(id)}>
            <div className="PaymentsTable__tags">
              {tags.length
                ? tags
                    .slice(0, 2)
                    .map(tag => <PaymentTag key={tag.id} title={tag.name} color={tag.color} isShortWidth />)
                : '-'}
              {tags.length > 2 && (
                <FormattedMessage id="justMoreTags" values={{ count: tags.length - 2 }}>
                  {txt => <span className="PaymentsTable__tags__caption">{txt}</span>}
                </FormattedMessage>
              )}
            </div>
          </RowLink>
        ),
      },
      ...extraColumns,
    ],
    [selectedMetadata]
  );
  /* eslint-enable */

  const staticGridTemplateColumns = `
  minmax(80px, 80px)
  minmax(150px, 1fr)
  minmax(110px, 1fr)
  minmax(110px, 1fr)
  minmax(110px, 1fr)
  minmax(110px, 1fr)
  minmax(150px, 1fr)
  minmax(130px, 1fr)
  minmax(130px, 1fr)
  minmax(130px, 1fr)
  minmax(min-content, 1fr)
  minmax(150px, 1fr)
`;

  const getDynamicColumns = () => (extraColumns.length ? `repeat(${extraColumns.length}, minmax(150px, 1fr))` : '');
  const getColumnWidths = () => `${staticGridTemplateColumns} ${getDynamicColumns()}`;

  const rowProps = (state, rowInfo) => ({
    onClick: () => onRowClick(rowInfo.original.id),
    style: {
      display: 'grid',
      gridTemplateColumns: getColumnWidths(),
    },
  });

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [isExportMenuModalOpen, setIsExportMenuModalOpen] = useState(false);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState('');
  const [exportExt, setExportExt] = useState(null);

  useEffect(() => {
    if (isExportMenuModalOpen) {
      setSelectedTimePeriod(
        `${moment(moment().add(-7, 'day').startOf('day')).format('YYYY-MM-DD')}_${moment(
          moment().startOf('day')
        ).format('YYYY-MM-DD')}`
      );
    }
  }, [isExportMenuModalOpen]);

  useEffect(() => {
    getPaymentMetadataKeys(slugsMap[type]);
  }, [getPaymentMetadataKeys]);

  return (
    <div className="PaymentsTable">
      <div className="PaymentsTable__buttons">
        <div className="PaymentsTable__buttons__metadata">
          {metadataKeys.length > 0 && (
            <BaseSelectField
              name="metadata"
              value={selectedMetadata}
              isMulti
              textId="boChooseMetadataColumns"
              options={metadataOptions}
              onChange={handleMetadataChange}
            />
          )}
        </div>
        {allowManagePaymentsExports && exportButtonCaptionSlug && (
          <div className="PaymentsTable__export-buttons">
            <DefaultButton
              type="button"
              textId={exportButtonCaptionSlug}
              onClick={() => history.push(`/backoffice/${type}/exports`)}
            />
            <div className="PaymentsTable__export-buttons--select">
              <BaseSelectField
                name="import"
                textId="justExport"
                options={[
                  { value: 'xlsx', label: 'Export in xlsx' },
                  { value: 'csv', label: 'Export in csv' },
                ]}
                onChange={option => {
                  setExportExt(option.value);
                  setIsExportMenuModalOpen(true);
                }}
                disabled={isExportPaymentBeingCreated}
              />
            </div>
          </div>
        )}
      </div>

      <PaginationTable
        data={data}
        loading={loading}
        columns={columns}
        onRowClick={onRowClick}
        getList={getList}
        location={location}
        history={history}
        params={params}
        isResponsive
        getTheadTrProps={() => ({
          style: {
            display: 'grid',
            gridTemplateColumns: getColumnWidths(),
          },
        })}
        getTrProps={rowProps}
      />
      <BasicModal
        captionId="selectTimePeriodOfExport"
        isOpen={isExportMenuModalOpen}
        onRequestClose={() => setIsExportMenuModalOpen(false)}>
        <DateRangeField
          yearsOptions={yearsOptions}
          input={{ value: selectedTimePeriod, onChange: setSelectedTimePeriod }}
          meta={{ touched: true, error: false }}
          noRedux
          relativeInner
          daysLimit={30}
        />
        <div className="PaymentsTable--onlyExecutedField">
          <Checkbox checked={onlyExecutedChecked} onClick={handeToggleOnlyExecutedField} />
          <FormattedMessage id="justSetOnlyExecuted" />
        </div>
        <DefaultButton
          textId="justExport"
          type="button"
          filled
          disabled={!selectedTimePeriod}
          onClick={() => {
            setIsExportMenuModalOpen(false);
            createBackOfficePaymentExport({
              exportsType,
              pushToExportsFunc,
              ext: exportExt,
              timePeriod: selectedTimePeriod,
              onlyExecuted: onlyExecutedChecked,
            });
          }}
        />
      </BasicModal>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

PaymentsTable.propTypes = {
  onRowClick: PropTypes.func.isRequired,
  allowManagePaymentsExports: PropTypes.bool.isRequired,
  isExportPaymentBeingCreated: PropTypes.bool.isRequired,
  createBackOfficePaymentExport: PropTypes.func.isRequired,
  exportButtonCaptionSlug: PropTypes.string,
  data: PropTypes.object.isRequired,
  type: PropTypes.string,
  getList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  hasPaymentOperationManagers: PropTypes.bool.isRequired,
  getPaymentMetadataKeys: PropTypes.func.isRequired,
  theme: PropTypes.object,
  metadataKeys: PropTypes.array,
};

PaymentsTable.defaultProps = {
  exportButtonCaptionSlug: '',
  type: '',
  metadataKeys: [],
  theme: {},
};

export default connect(
  state => ({
    isExportPaymentBeingCreated: state.backoffice.payments.isExportPaymentBeingCreated,
    metadataKeys: state.backoffice.payments.metadataKeys,
  }),
  {
    createBackOfficePaymentExport: ({ exportsType, pushToExportsFunc, ext, timePeriod, onlyExecuted }) =>
      createBackOfficePaymentExport.request({ exportsType, pushToExportsFunc, ext, timePeriod, onlyExecuted }),
    getPaymentMetadataKeys: captionSlug => getPaymentMetadataKeys.request(captionSlug),
  }
)(injectIntl(withTheme()(PaymentsTable)));
export { PaymentsTable };
