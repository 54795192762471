import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';
import { withRouter, useParams } from 'react-router-dom';
import { EditIcon } from 'common/icons';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { PageLayout, ActionsButton, DefaultButton, LoginCell, BasicModal } from 'common/components';

import { ChallengeItem } from 'client/accounts/_components';
import { ModalHOC } from 'common';

import AccountPageOnlyTradingTable from 'client/accounts/AccountPageOnlyTradingTable';

import { getTradingAccountList } from '../../../_redux/actions';

import { ChallengePageChart, ChangeRecieveRefundableFeeMethod } from '../../_components';
import { staticStyles } from './style';
import { getChallenge } from '../../_redux';
import CheckIcon from './CheckIcon';
import CrossIcon from './CrossIcon';

const ChallengePage = ({
  accounts = [],
  challenge,
  isWalletAccountAddonEnabled,
  getTradingAccountList,
  baseValue,
  challengeIsLoaded,
  getChallenge,
  showModal,
  history,
}) => {
  const currentWithdrawAccount = useMemo(() => {
    if (!challengeIsLoaded) {
      return null;
    }

    if (!challenge.withdrawAccount) {
      return null;
    }

    return challenge.withdrawAccount;
  }, [challenge, challengeIsLoaded]);

  useEffect(() => {
    getTradingAccountList();
  }, [getTradingAccountList]);

  const { challengeId } = useParams();
  useEffect(() => {
    getChallenge(challengeId);
  }, []);

  const [refundFeeModal, setRefundFeeModal] = useState(false);

  if (!challengeIsLoaded) {
    return null;
  }

  return (
    <PageLayout
      captionSlug={`Challenge ${challenge.challenge?.title}`}
      showReload
      onReloadClick={() => {
        getChallenge(challengeId);
      }}
      reloading={false}>
      <div className="ChallengePageWrapper">
        <div className="ChallengePage__ChallengeConditions">
          <FM id="justChallengeConditions">{txt => <h3>{txt}</h3>}</FM>

          <DefaultButton
            textId="justAboutChallenges"
            onClick={() => {
              showModal({
                caption: { id: 'justAboutChallenges' },
                content: {
                  id: 'justAboutChallengesTogether',
                },
                actionButton: {
                  textId: 'justGoToChallengePage',
                  handler: () => {
                    history.push('/challenges/');
                  },
                },
                cancelButton: {
                  textId: 'justClose',
                },
              });
            }}
          />
        </div>

        <div className="ChallengePage__Description">{challenge.challenge.description}</div>

        <table className="ChallengePage__Table" width="100%">
          <tr>
            <td>
              <FM id="justMinimalTradingDays" />
            </td>
            <td>
              <FM id="justMaximumDailyLoss" />
            </td>
            <td>
              <FM id="justMaximumLoss" />
            </td>
            <td>
              <FM id="justProfitTarget" />
            </td>
          </tr>
          <tr>
            <td>{challenge.level.minimum_trading_days}</td>
            <td>{`${challenge.level.maximum_daily_loss.amount} ${challenge.level.maximum_daily_loss.currency}`}</td>
            <td>{`${challenge.level.maximum_total_loss.amount} ${challenge.level.maximum_total_loss.currency}`}</td>
            <td>{`${challenge.level.target.amount} ${challenge.level.target.currency}`}</td>
          </tr>
        </table>

        {challengeIsLoaded && <ChallengeItem activeChallenge={challenge} noRedirect />}

        <div className="ChallengePageChart__Under">
          <div className="ChallengePageChart__Item">
            <FM id="justMaximumDailyLoss" />
            <div className="ChallengePageChart__Value">
              {`${challenge.maximumDailyLoss?.amount} ${challenge.maximumDailyLoss?.currency}`}
              {challenge.maximumDailyLoss?.amount < challenge.level.maximum_daily_loss.amount ? (
                <CheckIcon />
              ) : (
                <CrossIcon />
              )}
            </div>
          </div>
          <div className="ChallengePageChart__Item">
            <FM id="justMaximumTotalLoss" />
            <div className="ChallengePageChart__Value">
              {`${challenge.maximumTotalLoss?.amount} ${challenge.maximumTotalLoss?.currency}`}
              {challenge.maximumTotalLoss?.amount > challenge.level.maximum_total_loss.amount ? (
                <CheckIcon />
              ) : (
                <CrossIcon />
              )}
            </div>
          </div>
          <div className="ChallengePageChart__Item">
            <FM id="justTotalProfit" />
            <div className="ChallengePageChart__Value">
              {`${challenge.totalProfit.amount} ${challenge.totalProfit.currency}`}
              {+challenge.totalProfit.amount >= 0 ? <CheckIcon /> : <CrossIcon />}
            </div>
          </div>
        </div>
        {challenge?.challenge?.cost && (
          <>
            <div className="ChallengePageWrapper__RefundableFee">
              <FM id="justRefundableFee">{txt => <span>{txt}:</span>}</FM>
              <div>{challenge?.challenge?.cost ?? '---'}</div>
              <br />
            </div>

            <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              <FM id="justRefundableFeeWillBeSentToYourAfterCompleteing" />:
              {currentWithdrawAccount ? (
                <LoginCell
                  title={currentWithdrawAccount.accountTypeTitle}
                  login={currentWithdrawAccount.login}
                  challenge
                />
              ) : (
                <FM id="boWallet">{txt => <span className="RefundableFee--wallet">{txt}</span>}</FM>
              )}
              <ActionsButton
                tooltipId="Edit__tooltip"
                tooltipTextId="justEditChallengeWithdrawAccount"
                edit
                onClickFunc={() => setRefundFeeModal(true)}>
                <EditIcon />
              </ActionsButton>
            </div>
          </>
        )}
      </div>

      {challengeIsLoaded && (
        <div className="ChallengePage__Chart">
          <ChallengePageChart chart={challenge.chart} challenge={challenge} />
        </div>
      )}
      {challengeIsLoaded && (
        <AccountPageOnlyTradingTable
          accountPlatformSlug={challenge.account.platformSlug}
          accountId={challenge.account.id}
        />
      )}
      <BasicModal
        isOpen={refundFeeModal}
        captionId="justEditChallengeWithdrawAccount"
        onRequestClose={() => setRefundFeeModal(false)}>
        <ChangeRecieveRefundableFeeMethod
          challengeId={challenge.id}
          accounts={accounts}
          toggleModal={() => setRefundFeeModal(false)}
          isWalletAccountAddonEnabled={isWalletAccountAddonEnabled}
          baseValue={baseValue}
        />
      </BasicModal>
      <style jsx>{staticStyles}</style>
    </PageLayout>
  );
};

export default compose(
  withRouter,
  ModalHOC,
  connect(
    state => ({
      accounts: state.client.common.accounts,
      challenge: state.client.challenges.challenge,
      challengeIsLoaded: state.client.challenges.challengeIsLoaded,
      isWalletAccountAddonEnabled: state.interfaceConfig.addons?.some(addon => addon === 'walletAccount'),
      baseValue: state.interfaceConfig.baseValue,
    }),
    {
      getTradingAccountList: () => getTradingAccountList.request(),
      getChallenge: id => getChallenge.request(id),
    }
  )
)(ChallengePage);
