import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { injectIntl } from 'react-intl';

import { BaseTextField, BaseSelectField, RangeField, BaseDateRangeField } from '../form-fields';

import { staticStyles } from './style';

const SearchPanel = ({
  history,
  location,
  filters,
  inputs,
  rangeFields,
  dateRangeField,
  withoutSearch,
  getList,
  params,
  intl,
  loading,
  searchTextId,
}) => {
  const query = useMemo(() => queryString.parse(location.search), [location.search]);

  const [search, setSearch] = useState(query.search || '');
  const [accessors, setAccessors] = useState({});

  useEffect(() => {
    if (Array.isArray(inputs)) {
      inputs.forEach(({ accessor }) => {
        setAccessors(accessors => ({ ...accessors, [accessor]: query[accessor] }));
      });
    }
  }, [inputs, query]);

  useEffect(() => {
    if (Array.isArray(filters)) {
      filters.forEach(({ accessor, isMulti, options }) => {
        if (isMulti) {
          const options = query[accessor];
          if (options) {
            if (Array.isArray(options)) {
              setAccessors(accessors => ({
                ...accessors,
                [accessor]: options.map(value => ({ value, label: value })) || '',
              }));
            } else {
              setAccessors(accessors => ({ ...accessors, [accessor]: { value: options, label: options } || '' }));
            }
          }
        } else {
          let value = query[accessor];
          let elem = options.find(item => item.value?.toString() === value?.toString());

          if (typeof value === 'undefined') {
            value = '';
            elem = { value: '', label: intl.formatMessage({ id: 'justAll' }) };
          }

          if (Array.isArray(accessor)) {
            const rangeAccessors = {};

            for (let i = 0; i < accessor.length; i += 1) {
              const value = query[accessor[i]] || '';
              const elem = options.find(item => value !== '' && item.value.includes(value));
              rangeAccessors[accessor[i]] = { value, label: elem ? elem.label : intl.formatMessage({ id: 'justAll' }) };
            }

            setAccessors(accessors => ({ ...accessors, ...rangeAccessors }));

            return;
          }

          setAccessors(accessors => ({ ...accessors, [accessor]: { value, label: elem ? elem.label : '' } || '' }));
        }
      });
    }
  }, [filters, intl, query]);

  const handleChange = (queryName, search) => {
    let query = { page: 1, page_size: 10, ...params.search };

    if (Array.isArray(queryName)) {
      const rangeAccessors = queryName.reduce((acc, key, index) => ({ ...acc, [key]: search.value[index] }), {});
      query = { ...query, ...rangeAccessors };
    } else {
      query = { ...query, [queryName]: search || undefined };
    }

    history.replace({
      ...location,
      search: queryString.stringify(query),
    });
    getList({ search: query });
  };

  const handleSearchChange = e => {
    const search = e.target.value;
    setSearch(search);
  };

  const handleSearchKeyPress = e => {
    const query = { page: 1, page_size: 10, ...params.search, search: search || undefined };
    const { key } = e;

    if (key === 'Enter') {
      history.replace({
        ...location,
        search: queryString.stringify(query),
      });
      getList({ search: query });
    }
  };

  const handleInputsChange = (accessor, e) => {
    setAccessors({ ...accessors, [accessor]: e.target.value });
    handleChange(accessor, e.target.value, true);
  };

  const handleFilterChange = (accessor, options, isMulti) => {
    if (Array.isArray(accessor)) {
      const rangeAccessors = accessor.reduce(
        (acc, key, index) => ({ ...acc, [key]: { value: options.value[index], label: options.label } }),
        {}
      );

      setAccessors({ ...accessors, ...rangeAccessors });
      handleChange(accessor, options);

      return;
    }

    setAccessors({ ...accessors, [accessor]: options });
    let value;
    if (options) {
      value = isMulti ? options.map(option => option.value) : options.value;
    }
    handleChange(accessor, value);
  };

  const handleMobileFilterChange = (accessor, e) => {
    const value = e.target.value;
    const label = e.target.options[e.target.selectedIndex].text;
    setAccessors({ ...accessors, [accessor]: { value, label } });
    handleChange(accessor, value);
  };

  const handleRangeFieldChange = ([minSize, maxSize]) => {
    const query = {
      page: 1,
      page_size: 10,
      ...params.search,
      [rangeFields.queryMin]: minSize,
      [rangeFields.queryMax]: maxSize,
    };
    history.replace({
      ...location,
      search: queryString.stringify(query),
    });
    getList({ search: query });
  };

  const getDateRangeFieldValue = () => {
    const startDate = query[dateRangeField.queryStartDate];
    const endDate = query[dateRangeField.queryEndDate];

    if (!startDate || !endDate) return '';

    return `${startDate}_${endDate}`;
  };

  const handleDateRangeFieldChange = date => {
    const [startDate, endDate] = date.split('_');

    const query = {
      page: 1,
      page_size: 10,
      ...params.search,
      [dateRangeField.queryStartDate]: startDate,
      [dateRangeField.queryEndDate]: endDate,
    };
    history.replace({
      ...location,
      search: queryString.stringify(query),
    });
    getList({ search: query });
  };

  return (
    <div className="SearchPanel">
      <div className="SearchPanel__inputs-wrapper">
        {!withoutSearch && (
          <BaseTextField
            type="search"
            name="search"
            textId={searchTextId || 'justSearch'}
            value={search}
            loading={loading}
            onChange={handleSearchChange}
            onKeyPress={handleSearchKeyPress}
          />
        )}
        {!!inputs.length &&
          inputs.map((field, id) => (
            <BaseTextField
              type="search"
              name={field.accessor}
              textId={field.title}
              onChange={value => handleInputsChange(field.accessor, value)}
              value={accessors[field.accessor] ?? ''}
              key={`input_${id}`}
            />
          ))}
        {!!filters.length &&
          filters.map((filter, id) => (
            <BaseSelectField
              value={Array.isArray(filter.accessor) ? accessors[filter.accessor[0]] : accessors[filter.accessor]}
              name={filter.accessor}
              textId={filter.title}
              onChange={value => handleFilterChange(filter.accessor, value, filter.isMulti)}
              onMobileChange={e => handleMobileFilterChange(filter.accessor, e)}
              options={filter.options}
              isMulti={filter.isMulti}
              placeholder={filter.placeholder}
              searchable={!!filter.searchable}
              key={`filter_${id}`}
            />
          ))}
        {!!rangeFields && (
          <RangeField
            textId="justSize"
            formatLabelText="Mb"
            minValue={rangeFields.minValue}
            maxValue={rangeFields.maxValue}
            onFinalChange={handleRangeFieldChange}
          />
        )}
        {!!dateRangeField && (
          <BaseDateRangeField
            placeholderId={dateRangeField.title}
            value={getDateRangeFieldValue()}
            onChange={handleDateRangeFieldChange}
          />
        )}
      </div>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

SearchPanel.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getList: PropTypes.func.isRequired,
  filters: PropTypes.array,
  inputs: PropTypes.array,
  params: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  rangeFields: PropTypes.object,
  dateRangeField: PropTypes.object,
  withoutSearch: PropTypes.bool,
  loading: PropTypes.bool,
  searchTextId: PropTypes.string,
};

SearchPanel.defaultProps = {
  filters: [],
  inputs: [],
  dateRangeField: null,
  rangeFields: null,
  withoutSearch: false,
  loading: false,
  searchTextId: null,
};

export default injectIntl(SearchPanel);
export { SearchPanel };
