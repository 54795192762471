import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';

import { DefaultButton } from 'common/components';
import { withTheme } from 'common/styling/theme';
import { rejectRewards } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const RejectRewardsAllForm = ({
  filter,
  handleSubmit,
  toggleRejectRewardsAllModal,
  submitting,
  invalid,
  error,
  theme,
  dispatch,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  useEffect(() => {}, []);
  const submit = handleSubmit(values => rejectRewards({ ...values, selectAll: true, filter }, dispatch));

  return (
    <form className="RejectRewardsAllForm" onSubmit={submit}>
      <div className="RejectRewardsAllForm__line">
        <DefaultButton
          type="submit"
          textId="justRejectRewards"
          loading={submitting}
          disabled={invalid || submitting}
          filled
        />
        <DefaultButton textId="justCancel" onClick={toggleRejectRewardsAllModal} />
      </div>
      {error && <strong>{error}</strong>}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </form>
  );
};

RejectRewardsAllForm.propTypes = {
  filter: PropTypes.object.isRequired,
  toggleRejectRewardsAllModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
  theme: PropTypes.object,
};

RejectRewardsAllForm.defaultProps = {
  error: '',
  theme: {},
};

export default compose(
  withTheme(),
  reduxForm({
    form: 'RejectRewardsForm',
    onSubmitSuccess(result, dispatch, props) {
      props.toggleRejectRewardsAllModal();
    },
  })
)(RejectRewardsAllForm);
export { RejectRewardsAllForm };
