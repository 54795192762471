import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ModalHOC } from 'common';

import { PageLayout, PageContentPreloader, NoItemsPanel, DefaultButton } from 'common/components';
import noPaymentsImage from 'common/images/noPayments.svg';
import { StatusesTable, CreateStatusDescriptionForm } from '../../_components';

import { getStatusesList, updateStatusDescription, deleteStatusInfo, getStatusDescription } from '../../_redux';

import staticStyles from './style';

const StatusesManagementPage = ({
  statusInfoList,
  statusInfoListAreLoaded,
  getStatusesList,
  deleteStatusInfo,
  statusDescription,
  getStatusDescription,
  statusDescriptionIsLoaded,
  showModal,
}) => {
  useEffect(() => {
    getStatusesList();
    getStatusDescription();
  }, [getStatusesList, getStatusDescription]);

  const history = useHistory();

  let initialValues;
  if (statusDescriptionIsLoaded) {
    initialValues = {
      title: statusDescription.title.reduce((acc, title) => ({ ...acc, [title.lang]: title.text }), {}),
      description: statusDescription.description.reduce(
        (acc, description) => ({ ...acc, [description.lang]: description.text }),
        {}
      ),
    };
  }

  const handleDeleteStatus = statuesId => {
    showModal({
      caption: { id: 'confirmText' },
      content: {
        id: 'confirmDeleteStatus',
      },
      actionButton: {
        textId: 'justYes',
        handler: () => deleteStatusInfo(statuesId),
        type: 'error',
      },
      cancelButton: {
        textId: 'justNo',
      },
    });
  };

  return (
    <PageLayout captionSlug="justStatuses">
      <div className="StatusesManagementPage__create-inner">
        <DefaultButton textId="justAddStatus" onClick={() => history.push('/backoffice/statuses/create')} plus filled />
      </div>
      <PageContentPreloader ready={statusInfoListAreLoaded} type="smallLines">
        {statusInfoList.length ? (
          <StatusesTable
            data={statusInfoList}
            onRemoveStatus={handleDeleteStatus}
            onEditStatus={id => history.push(`/backoffice/statuses/${id}/edit`)}
            onSortGetData={getStatusesList}
          />
        ) : (
          <NoItemsPanel icon={noPaymentsImage} captionSlug="noStatusesYet" />
        )}
      </PageContentPreloader>

      {statusDescriptionIsLoaded && (
        <CreateStatusDescriptionForm
          submitFunction={updateStatusDescription}
          history={history}
          successMessageId="statusesCreatedSuccessfully"
          initialValues={initialValues}
        />
      )}
      <style jsx>{staticStyles}</style>
    </PageLayout>
  );
};

StatusesManagementPage.propTypes = {
  statusInfoList: PropTypes.array.isRequired,
  statusInfoListAreLoaded: PropTypes.bool.isRequired,
  getStatusesList: PropTypes.func.isRequired,
  deleteStatusInfo: PropTypes.func.isRequired,
  statusDescription: PropTypes.object.isRequired,
  getStatusDescription: PropTypes.func.isRequired,
  statusDescriptionIsLoaded: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    statusInfoList: state.backoffice.statuses.statusInfoList,
    statusInfoListAreLoaded: state.backoffice.statuses.statusInfoListAreLoaded,
    statusDescription: state.backoffice.statuses.statusDescription,
    statusDescriptionIsLoaded: state.backoffice.statuses.statusDescriptionIsLoaded,
  }),
  {
    getStatusesList: sort => getStatusesList.request(sort),
    deleteStatusInfo: id => deleteStatusInfo.request(id),
    getStatusDescription: id => getStatusDescription.request(id),
  }
)(ModalHOC(StatusesManagementPage));
export { StatusesManagementPage };
