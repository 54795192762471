import axios from 'axios';

export const getChallenges = () => axios.get(`/proptrading/challenges/`);

export const getUserChallenges = () => axios.get(`/proptrading/user-challenges/`);

export const changeRefundableFeeRecieveMethod = (challengeId, withdrawAccount) =>
  axios.patch(`/proptrading/user-challenges/${challengeId}/`, { withdrawAccount });

export const activateChallenge = (challengeId, withdrawAccount, account) =>
  axios.post(
    `/proptrading/user-challenges/`,
    { challenge: challengeId, withdrawAccount, account },
    { validateStatus: status => status < 409 }
  );

export const getChallenge = id => axios.get(`/proptrading/user-challenges/${id}/`);

export const restartChallenge = challengeId =>
  axios.post(`/proptrading/user-challenges/`, { challenge: challengeId }, { validateStatus: status => status < 409 });

export const archiveChallenge = challengeId => axios.post(`/proptrading/user-challenges/${challengeId}/close/`);

export const getChallegesLeaderboard = search => axios.get('/proptrading/leaderboard/', { params: { ...search } });
