import React from 'react';
import PropTypes from 'prop-types';
import { ActionsButton, PaginationTable, StatusCell, DateCell, Tooltip } from 'common/components';
import { injectIntl } from 'react-intl';
import { EditIcon } from 'common/icons';
import { withTheme } from 'common/styling/theme';
import Checkbox from '../Checkbox';
import { staticStyles, getDynamicStyles } from './style';

const CommissionsTable = ({
  selectedRecords,
  setSelectedRecords,
  isSelectAllRecords,
  setIsSelectAllRecords,
  data,
  onEditRecord,
  onRowClick,
  getList,
  loading,
  history,
  location,
  params,
  intl,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const handleCheck = (e, id, availableAmount) => {
    e.stopPropagation();
    setIsSelectAllRecords(false);
    if (selectedRecords.some(item => item.id === id)) {
      setSelectedRecords(selectedRecords.filter(item => item.id !== id));
    } else {
      setSelectedRecords([...selectedRecords, { id, availableAmount }]);
    }
  };
  const editCommissionRecord = (e, id) => {
    e.stopPropagation();
    onEditRecord(id);
  };

  const checkRowDisabled = status => ['WITHDRAW', 'REJECTED'].includes(status);

  const REWARD_FREQUENCIES = {
    dailyInstant: intl.formatMessage({ id: 'justDailyInstant' }),
    monthlyInstant: intl.formatMessage({ id: 'justMonthlyInstant' }),
    monthlyDelayed: intl.formatMessage({ id: 'justMonthlyDelayed' }),
  };

  /* eslint-disable */
  const columns = [
    {
      accessor: 'checkbox',
      Header: () => (
        <Tooltip id={`CommissionsTable-selectall-checkbox`} textId="justCommissionsTableSelectAllCheckbox">
          <Checkbox onClick={e => setIsSelectAllRecords(prev => !prev)} checked={isSelectAllRecords} />
        </Tooltip>
      ),
      renderHeader: true,
      Cell: ({
        row: {
          original: { id, status, availableAmount },
        },
      }) => (
        <Checkbox
          onClick={e => {
            setIsSelectAllRecords(false);
            handleCheck(e, id, availableAmount);
          }}
          checked={selectedRecords.some(item => item.id === id)}
          disabled={status !== 'READY_FOR_PAY'}
        />
      ),
      isPreviewCell: true,
    },
    {
      Header: 'justId',
      accessor: 'id',
      Cell: ({
        row: {
          original: { id },
        },
      }) => <span>{id}</span>,
      isPreviewCell: true,
    },
    {
      Header: 'justAccount',
      accessor: 'account',
      Cell: ({
        row: {
          original: { account },
        },
      }) => <span>{account}</span>,
      isPreviewCell: true,
    },
    {
      Header: 'justDate',
      accessor: 'date',
      Cell: ({
        row: {
          original: { date },
        },
      }) => <DateCell value={date} hideTime nowrap />,
    },
    {
      Header: 'justPartnerName',
      accessor: 'partnerName',
      Cell: ({
        row: {
          original: { partnerName },
        },
      }) => <span>{partnerName}</span>,
    },
    {
      Header: 'justCalculatedReward',
      accessor: 'calculated_amount',
      Cell: ({
        row: {
          original: { calculatedAmount },
        },
      }) => (
        <span className={calculatedAmount}>
          {calculatedAmount.amount} {calculatedAmount.currency}
        </span>
      ),
    },
    {
      Header: 'justCurrentReward',
      accessor: 'available_amount',
      Cell: ({
        row: {
          original: { availableAmount },
        },
      }) => (
        <span className={availableAmount}>
          {availableAmount.amount} {availableAmount.currency}
        </span>
      ),
      isPreviewDescriptionCell: true,
    },
    {
      Header: 'boStatus',
      accessor: '_status',
      Cell: ({
        row: {
          original: { status },
        },
      }) => <StatusCell statusCode={status} />,
      isPreviewCell: true,
    },
    {
      Header: 'justIbProgram',
      accessor: 'ibProgramm',
      Cell: ({
        row: {
          original: { ibProgramm },
        },
      }) => <span>{ibProgramm}</span>,
      isPreviewDescriptionCell: true,
    },
    {
      Header: 'justFrequencyOfReward',
      Cell: ({
        row: {
          original: { frequencyOfPayments },
        },
      }) => <p className="RewardType__cell">{REWARD_FREQUENCIES[frequencyOfPayments] ?? '—'}</p>,
    },
    {
      accessor: 'action',
      Header: () => null,
      Cell: ({
        row: {
          original: { id, status },
        },
      }) => (
        <ActionsButton
          tooltipId={`Edit__tooltip-${id}`}
          tooltipTextId="justEdit"
          edit
          onClickFunc={e => editCommissionRecord(e, id)}
          disabled={checkRowDisabled(status)}>
          <EditIcon />
        </ActionsButton>
      ),
      isPreviewCell: true,
    },
  ];
  /* eslint-enable */

  const rowProps = (state, rowInfo) => {
    const isRowDisabled = checkRowDisabled(rowInfo.original.status);
    return {
      onClick: () => onRowClick(rowInfo.original.id),
      className: isRowDisabled ? 'CommissionsTable__disabled-row' : '',
    };
  };

  return (
    <div className="CommissionsTable">
      <PaginationTable
        data={data}
        loading={loading}
        columns={columns}
        getList={getList}
        location={location}
        history={history}
        params={params}
        getTrProps={rowProps}
        onRowClick={onRowClick}
        isNewTable
      />
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

CommissionsTable.propTypes = {
  selectedRecords: PropTypes.array.isRequired,
  setSelectedRecords: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onEditRecord: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  getList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

CommissionsTable.defaultProps = {
  theme: {},
};

export default withTheme()(injectIntl(CommissionsTable));
export { CommissionsTable };
