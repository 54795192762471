import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { StatusCell, PaginationTable, DateCell, RowLink } from 'common/components/index';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const TransfersTable = ({
  data,
  pageLink,
  onRowClick,
  getList,
  loading,
  history,
  location,
  params,
  theme,
  intl,
  hasPaymentOperationManagers,
}) => {
  const TRANSFER_TYPE_SLUGS = {
    INNER: 'boInnerTransfer',
    TO_TRADER: 'boAnotherTrader',
    FROM_WALLET: 'fromWallet',
    TO_WALLET: 'toWallet',
    TO_TRADER_WALLET: 'toTraderWallet',
    TO_TRADER_LPA: 'justToLpa',
    TO_TRADER_WALLET_LPA: 'justToWalletLpa',
    INVEST_INNER: 'justInvestInner',
    TO_WALLET_INVEST: 'justToWalletInvest',
    FROM_WALLET_INVEST: 'justFromWalletInvest',
    INVEST_REWARD: 'justInvestReward',
  };

  /* eslint-disable */
  const columns = [
    {
      key: 'boId',
      accessor: 'id',
      Cell: ({ original: { id } }) => (
        <RowLink link={`/${pageLink}/${id}`} isPreviewCell>
          <span className="TransfersTable__sender-id">{id}</span>
        </RowLink>
      ),
      isPreviewCell: true,
    },
    {
      key: 'accountFrom',
      accessor: 'senderAccount',
      Cell: ({ original: { senderAccount, email, id } }) => (
        <RowLink link={`/${pageLink}/${id}`} isPreviewCell>
          <span className="TransfersTable__sender-account">
            {senderAccount || email || intl.formatMessage({ id: 'menuWallet' })}
          </span>
        </RowLink>
      ),
      isPreviewCell: true,
    },
    {
      key: 'accountTo',
      accessor: 'recipientAccount',
      Cell: ({ original: { recipientAccount, recipient, id } }) => (
        <RowLink link={`/${pageLink}/${id}`}>
          <span className="TransfersTable__sender-account">
            {recipientAccount || recipient || intl.formatMessage({ id: 'menuWallet' })}
          </span>
        </RowLink>
      ),
    },
    {
      key: 'boStatus',
      accessor: 'status',
      Cell: ({ original: { status, id } }) => (
        <RowLink link={`/${pageLink}/${id}`} isPreviewCell>
          <StatusCell statusCode={status} />
        </RowLink>
      ),
      isPreviewCell: true,
    },
    {
      key: 'boAmount',
      Cell: ({ original: { sendAmount, id } }) => (
        <RowLink link={`/${pageLink}/${id}`} isPreviewCell>
          <span className="TransfersTable__amount">
            {sendAmount.amount} {sendAmount.currency}
          </span>
        </RowLink>
      ),
      isPreviewCell: true,
    },
    ...(hasPaymentOperationManagers
      ? [
          {
            key: 'justManager',
            Cell: ({ original: { manager, id } }) => (
              <RowLink link={`/${pageLink}/${id}`}>
                <div>{manager ? `${manager.firstName} ${manager.lastName}` : '一'}</div>
              </RowLink>
            ),
          },
        ]
      : []),
    {
      key: 'boTransferType',
      Cell: ({ original: { transferType, id } }) => (
        <RowLink link={`/${pageLink}/${id}`}>
          {TRANSFER_TYPE_SLUGS[transferType] ? (
            <FormattedMessage id={TRANSFER_TYPE_SLUGS[transferType]}>
              {txt => <span className="TransfersTable__transferType">{txt}</span>}
            </FormattedMessage>
          ) : (
            <span className="TransfersTable__transferType" />
          )}
        </RowLink>
      ),
    },
    {
      key: 'boCreated',
      accessor: 'created',
      Cell: ({ original: { created, id } }) => (
        <RowLink link={`/${pageLink}/${id}`}>
          <DateCell value={created} />
        </RowLink>
      ),
    },
  ];
  /* eslint-enable */

  const rowProps = (state, rowInfo) => ({
    onClick: () => onRowClick(rowInfo.original.id),
  });

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="TransfersTable">
      <PaginationTable
        data={data}
        loading={loading}
        columns={columns}
        getTrProps={rowProps}
        onRowClick={onRowClick}
        getList={getList}
        location={location}
        history={history}
        params={params}
        isResponsive
      />
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

TransfersTable.propTypes = {
  onRowClick: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  pageLink: PropTypes.string.isRequired,
  getList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  theme: PropTypes.object,
  hasPaymentOperationManagers: PropTypes.bool.isRequired,
};

TransfersTable.defaultProps = {
  theme: {},
};

export default compose(injectIntl, withTheme())(TransfersTable);
export { TransfersTable };
